<template>
  <b-card class="normal-user-card">
    <b-card-body>
      <b-card-title>
        <b-avatar size="4rem" :src="data.profile_image"></b-avatar>

        <h6 class="m-0">{{ data.fullname }}</h6>
        <p class="font-weight-light" style="font-size: 12px">
          {{ data.email }}
        </p>
      </b-card-title>

      <div class="social mb-1">
        <span>Followers {{ data.followers.length }}</span>
        <span>Following {{ data.following.length }}</span>
      </div>
      <div class="nomad-details">
        <div>
          Nomadic Level <br />
          <strong> {{ data.nomad_type || "Not provided" }}</strong>
        </div>
        <div>
          Nomadic Level
          <br />
          <strong>{{ data.nomad_level || "Not provided" }}</strong>
        </div>
        <div>
          Challenge <br />
          <strong> {{ data.challenge || "Not provided" }} </strong>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
export default {
  props: ["data"],
  data() {
    return {};
  },
};
</script>

<style scoped>
.normal-user-card {
  text-align: center;
}
.nomad-details {
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  margin: auto;
  justify-content:center;
}
.nomad-details div {
  border: 1px solid #e83e8c;
  color: #e83e8c;
  font-size: 10px;
  padding: 4px 4px;
  border-radius: 8px;
  margin: 4px;
}
.nomad-details strong {
  color: black;
}
.normal-user-card img {
  width: 68px;
  height: 68px;
  border-radius: 50%;
  display: block;
  margin: auto;
}
.social {
  display: flex;
  flex-wrap: wrap;
  width: fit-content;
  margin: auto;
}
.social span {
  background-color: #f8c616;
  color: #fff;
  font-size: 12px;
  padding: 4px 8px;
  margin: 2px;
  border-radius: 8px;
}
</style>