<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="row mt-1">
          <div class="col-6">
            <label class="d-inline-flex align-items-center mt-2">
              Search
              <b-form-input
                v-model="filter"
                type="search"
                placeholder="Search..."
                class="form-control form-control-sm ml-2"
              ></b-form-input>
            </label>
          </div>
        </div>
        <div style="text-align: center; margin: 28px" v-if="dataLoading">
          <pulse-loader color="#e83e8c" />
        </div>
        <!-- User data -->
        <div class="row mt-2">
          <div
            v-if="tableData.length === 0 && !dataLoading"
            class="col-12 text-center"
          >
            <p>No entries found.</p>
          </div>
          <div
            class="col-sm-6 col-lg-4 col-xl-3"
            v-for="item in tableData"
            :key="item.id"
          >
            <a @click="selectUserAction(item)">
              <userCard :data="item" />
            </a>
          </div>
        </div>

        <div class="row" v-if="rows">
          <div class="col-sm-5">
            <div class="float-left">
              <label
                class="d-inline-flex align-items-center"
                style="min-width: 45px"
              >
                Show&nbsp;
                <b-form-select
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                  @change="perPageChange"
                ></b-form-select
                >&nbsp;entries
              </label>
            </div>
          </div>
          <div class="col-sm-7">
            <div class="float-right">
              <ul class="pagination pagination-rounded mb-4">
                <!-- pagination -->
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  @change="pageChange"
                ></b-pagination>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>


<script>
import Layout from "@/views/layouts/main";
import appConfig from "@/app.config";
import userCard from "@/components/user/normal-user-card";
import { user } from "@/config/api/users";
import { normalUserMethods } from "@/state/helpers";

export default {
  page: {
    title: "Normal users",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, userCard },
  data() {
    return {
      tableData: [],
      title: "Normal Users",
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 16,
      pageOptions: [16, 25, 50, 100],
      filter: "",
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      dataLoading: false,
      rows: 0,
      fields: [
        { key: "fullname", sortable: false, label: "Name" },
        { key: "nomadic", sortable: false, label: "Nomad" },
        { key: "Contact", sortable: false },
        { key: "createdAt", sortable: false },
        { key: "updatedAt", sortable: false },
      ],
    };
  },
  computed: {},
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  watch: {
    filter: {
      handler() {
        this.currentPage = 1; // Reset to first page on new search
        this.getNormalUsers();
      },
      immediate: true,
    },
  },
  created() {
    this.getNormalUsers();
  },
  methods: {
    ...normalUserMethods,
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getNormalUsers() {
      // this.dataLoading = true;
      this.dataLoading = true;
      this.tableData = [];
      const api = user.getAllNormalUsers;
      api.params = {
        page: this.currentPage,
        perPage: this.perPage,
        role: "Normal",
        query: this.filter,
      };
      this.generateAPI(api)
        .then((res) => {
          console.log(res);
          this.tableData = res.data.users;
          this.rows = res.data.userCount;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.dataLoading = false;
          console.log("Loaded");
        });
    },
    pageChange(val) {
      this.currentPage = val;
      this.getNormalUsers();
    },
    perPageChange() {
      this.getNormalUsers();
    },
    selectUserAction(item) {
      this.selectUserID(item._id);
      this.$router.push({ name: "NormalUsersdetails" });
    },
  },
};
</script>
